* {
  font-family: 'roboto', sans-serif;
}
.fix {
  overflow: hidden;
}
p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 10pt;
  line-height: 17pt;
}
a {
  color: #ffffff;
}
.clear {
  clear: both;
}
/* style for wow animation*/
.a1 {
  visibility: visible;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.a2 {
  visibility: visible;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.a3 {
  visibility: visible;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.a4 {
  visibility: visible;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.a5 {
  visibility: visible;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}
/* style for Typography */
h2,
h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
h2 {
  font-size: 22pt;
  line-height: 37pt;
  margin: 0px;
}
h3 {
  font-size: 14pt;
  line-height: 24pt;
}
h4 {
  font-size: 12pt;
  line-height: 20pt;
  opacity: 1;
}
h5 {
  font-size: 10pt;
  margin: 0px;
  padding: 0px;
  line-height: 15pt;
}
.fa {
  font-size: 22px;
}
/* style for

***** body  *****

*/
body {
  background-color: #eae8e7;
  color: #52504f;
  text-align: center;
}
.container {
  margin-top: 24px;
}
/* style for

***** Sidebar  *****

*/
.sidebar {
  text-align: left;
  color: #ffffff;
  background-color: #41a7e1;
  overflow: hidden;
  display: block;
}
.sidebar .heading {
  position: relative;
}
.sidebar .heading .feature-img {
  position: relative;
}
.sidebar .heading .feature-img img {
  width: 100%;
}
.sidebar .heading .button-collapse {
  display: block !important;
}
.sidebar .heading .nav-icon {
  position: absolute;
  top: 0;
  padding: 0 16px;
}
.sidebar .heading nav {
  background-color: initial;
  box-shadow: initial;
}
.sidebar .heading nav .button-collapse i {
  height: 30px;
  font-size: 18pt;
  line-height: 24pt;
  background-color: #5da4d9;
  margin-top: 20px;
  padding: 0px 6px;
}
.sidebar .heading nav .side-nav h3 {
  color: #5da4d9;
  padding: 0 15px;
}
.sidebar .heading nav .side-nav li a:hover {
  color: #5da4d9;
}
.sidebar .heading nav .side-nav li:hover {
  background-color: transparent !important;
}
.sidebar .heading .title {
  position: absolute;
  right: 11px;
  bottom: 23px;
  color: white;
}
.sidebar .heading .title span {
  margin-top: -5px;
  font-size: 11pt;
  margin: 0px;
  padding: 0px;
  line-height: 15pt;
}
.sidebar .heading .title h2 a {
  height: 32px;
  width: 32px;
  display: inline-block;
  line-height: 1.2;
}
.sidebar .heading .title h2 {
  line-height: 1.2;
}
.sidebar .heading .title h2 img {
  transition: opacity .2s;
}
.sidebar .heading .title h2 div {
  height: 50px
}
.sidebar .icon {
  margin-top: 8px;
}
.sidebar .icon .fa {
  display: inherit;
  text-align: center;
}
.sidebar .sidebar-item {
  overflow: hidden;
  margin-top: 10px;
}
.sidebar .sidebar-item:first-child {
  margin-top: 30px;
}
.sidebar .section-item-details {
  padding-right: 26px;
  margin-bottom: 30px;
}
.sidebar .section-item-details p {
  margin: 0px;
}
.sidebar .info {
  border-bottom: 1px solid #4783c2;
  padding-left: 0px;
}
.sidebar .info span {
  opacity: 0.7;
  font-weight: 300;
}
.sidebar .personal {
  margin-bottom: 20px;
}
.sidebar a .personal img {
  height: 16px;
  background: 
    linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, #5da4d9, white, #5da4d9);
  background-size: 100% 0.2em, 0 0.2em;
  background-position: 50% 100%, 0.2em 100%;
  background-repeat: no-repeat;
  transition: background-size 600ms;
}
.sidebar a:hover .personal img,
.sidebar a:focus .personal img {
  background-size: 0 0.2em, 100% 0.2em;
 }

.sidebar .personal h4 {
  margin: 0px;
}
.sidebar .work h4 {
  margin-bottom: 0px;
}
.sidebar .address-details h4 {
  margin-top: 0px;
}
.sidebar .address-details h4 span {
  font-size: 10pt;
}
.sidebar .skill-line {
  padding-right: 30px;
  padding-left: 0px;
}
.sidebar .skill-line div {
  text-align: left;
}
.sidebar .skill-line .progress {
  background-color: #4783c2;
  height: 2px;
  overflow: visible;
}
.sidebar .skill-line .determinate {
  background-color: #ffffff;
}
.sidebar .skill-line .determinate .fa {
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: 0px;
  margin-left: 50%;
}
.sidebar .skill-line h3 {
  margin-top: 0px;
  margin-bottom: 20px;
}
.sidebar .skill-line span {
  opacity: initial;
}
/* style for

***** Sections *****

*/
#section {
  overflow: hidden;
}
.section {
  padding-left: 32px !important;
  text-align: left;
}
.section .fa {
  text-align: center;
  display: block;
  font-size: 30pt;
}
.section .section-wrapper {
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 24px;
  padding-bottom: 24px !important;
}
.section .section-wrapper .section-icon {
  margin-top: 40px;
}
.section .section-wrapper h2 {
  margin-top: 30px;
  margin-bottom: 25px;
}
/* style for

***** Work Experience  *****

*/

.achievements-content img { width: 500px; max-width:100% }

.custom-content {
  padding-right: 0px !important;
}
.custom-content-wrapper {
  padding-right: 30px;
  margin-bottom: 25px;
  border-bottom: 1px solid #bdbdbd;
}
.custom-content-wrapper-last {
  border-bottom: initial;
}
.custom-content-wrapper h3 {
  margin: 0px;
  font-size: 12pt;
  line-height: 20pt;
}
.custom-content-wrapper h3 span {
  color: #52504f;
}
.custom-content-wrapper span {
  color: #5da4d9;
  margin-top: 0px;
  font-size: 10pt;
  line-height: 15pt;
}
.custom-content-wrapper p {
  margin-top: 0px;
  margin-bottom: 25px;
}
/* style for

*****  Interests  *****

*/
.interests ul {
  padding-left: 0px;
}
.interests ul li {
  list-style-type: none;
  display: inline-block;
  width: 19%;
}
.interests ul li .fa {
  color: #5da4d9;
  text-align: left;
}
/* style for

***** Portfolio website  *****

*/
.website {
  padding-top: 40px !important;
  padding: 20px;
}
.website span {
  font-size: 12pt;
  line-height: 18pt;
}
.website span a {
  color: #5da4d9;
  word-wrap: break-word;
}
/* style for

***** blog  *****

*/
.post-title {
  margin-top: -5px;
  color: white;
  padding: 20px 30px;
}
.post-title h2 {
  margin: 0px;
  font-size: 14pt;
  line-height: 24pt;
}
.post-title span {
  opacity: 0.7;
}
.blog-post .thumbnail img {
  width: 100%;
  margin-bottom: -2px;
}
.blog-post:nth-child(odd) {
  padding-left: 0px !important;
}
.blog-post:nth-child(even) {
  padding-right: 0px !important;
}
.blog-post:last-child {
  padding-right: 0px !important;
}
#blog-post-1 {
  background-color: #e95855;
}
#blog-post-2 {
  background-color: #707281;
}
#blog-post-3 {
  background-color: #564d52;
}
#blog-post-4 {
  background-color: #47bac9;
}
#blog-post-5 {
  background-color: #68c02e;
}
.post-details {
  background-color: #ffffff;
  padding: 20px 30px;
  margin-bottom: 24px;
}
.featured-img {
  padding-right: 0px !important;
}
.caption {
  padding-left: 0px !important;
}
#caption .post-details {
  margin-bottom: 0px;
}
.comment .section-wrapper {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.comment .comment-img {
  padding: 10px;
}
.comment .comment-details {
  padding: 10px;
}
.comment .comment-details h4 {
  margin: 0px;
}
.comment .comment-details span {
  font-size: 9pt;
  line-height: 17pt;
}
.comment-form {
  padding-bottom: 0px;
  background-color: #fff;
  padding: 20px 50px !important;
}
/* style for
    **************
   * Cover latter * 
 
*/
.cover-latter {
  padding: 30px;
  margin-right: 5px;
  margin-bottom: 24px;
  background-color: white;
}
.cover-latter strong {
  font-weight: 400;
}
.cover-latter p:last-child {
  margin: 30px 0px;
}
.cover-latter p h2 {
  margin-bottom: 0px;
  font-size: 12pt;
  line-height: 20pt;
}
.cover-latter .cover-title span {
  font-size: 12pt;
  line-height: 20pt;
  font-weight: 500;
}
.cover-latter .cover-title h2 {
  font-size: 12pt;
  line-height: 20pt;
  margin-top: 20px;
}
.cover-latter .cover-title h4 {
  font-weight: 500;
}
.cover-latter .cover-title p {
  margin-top: 0px;
}
.cover-latter .signature h4 {
  margin: 0px;
}
/* ***** Contact  *****
*
*/
.section .g-map {
  padding-bottom: 0px !important;
}
.section .g-map #map {
  width: 100%;
  height: 350px;
}
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #5da4d9;
}
input[type=text]:focus:not([readonly]) + label,
input[type=password]:focus:not([readonly]) + label,
input[type=email]:focus:not([readonly]) + label,
input[type=url]:focus:not([readonly]) + label,
input[type=time]:focus:not([readonly]) + label,
input[type=date]:focus:not([readonly]) + label,
input[type=datetime-local]:focus:not([readonly]) + label,
input[type=tel]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label,
input[type=search]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #5da4d9;
}
.waves-me:focus {
  background-color: #5da4d9;
  color: #fff;
}
.btn {
  margin-left: 10px;
  background-color: #5da4d9;
}
.btn:hover,
.btn:focus {
  background-color: #5da4d9;
}
.email-success,
.email-failed {
  display: none;
  text-align: left !important;
  margin: 0 0 0 0 !important;
}
.btn.disabled {
  background-color: #5da4d9;
  color: #fff;
  cursor: default;
}
.btn.disabled:focus {
  background-color: #5da4d9;
  color: #fff;
  cursor: default;
}
.btn.disabled:hover {
  background-color: #5da4d9;
  color: #fff;
  cursor: default;
}
#msgSubmit {
  font-size: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.text-danger {
  color: red;
}
.text-success {
  color: #5da4d9 ;
}
/*
|----------------------------------------------------------------------------
| PORTFOLIO
|----------------------------------------------------------------------------
*/
.portfolio-nav ul {
  margin: 30px;
  text-align: center;
}
.portfolio-nav ul li {
  list-style-type: none;
  display: inline;
  margin: 10px;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: uppercase;
}
.portfolio-nav ul li:hover {
  border-bottom: 1px solid #bdbdbd;
}
.mix {
  display: none;
}
.fancybox-overlay {
  background-color: rgba(25, 22, 22, 0.69);
}
.back-btn {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}
.back-btn .fa {
  display: inline;
}
#back-button {
  display: none;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 15px;
}
#back-button i {
  margin-right: 10px;
  font-size: 30px;
  opacity: 0.9;
  position: relative;
  top: 5px;
  margin-right: 15px;
}
#back-button img {
  padding: 10px;
}
.full-project {
  padding: 30px !important;
}
.single-project {
  background-color: #fff;
  margin-bottom: 20px;
}
.single-project .full-project-title {
  font-size: 30px;
}
.single-project-img {
  margin: 0;
  padding: 0 !important;
}
.single_image {
  color: #fff;
  text-align: left;
}
.single_image h2 {
  font-size: 22px;
  line-height: 34px;
  margin: 10px 0;
}
.single_image p {
  text-transform: normal;
  margin-top: 10px;
  opacity: 0.8;
}
.full-portfolio {
  position: relative;
}
.full-portfolio .section-wrapper {
  padding-bottom: 0 !important;
}
.full-project-content {
  padding: 30px;
}
.project-information li a {
  color: #5da4d9;
}

.skill-line div {
  border-bottom: 1px solid #4783c2;
  padding: 4px 0;
}

.footer-content a { color: #039be5;}
.footer-content i.fa-external-link {
  display: inline; 
  font-size: 15px;
  padding-right: 4px;
  font-weight: normal;
}

a:hover, a:hover h4 {
  text-decoration: underline;
}

.achievements-content a img {
  transform: scale(1.00001);
  margin-bottom: 8px;
  transition: all .2s ease-in-out;
}

.achievements-content a:hover img {
  transform: scale(1.007);
}

.work-content ol {
  text-align: left;
}

.fadeIn {
  opacity: 0;
  animation-delay: .2s;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

.invisible {
  opacity: 0;
}

.container .scrollToTopButton {
  color: #fff;
  opacity: .85;
  transition: opacity .3s; 
  border-color: #5da4d9;
  background: #3a8ece;
}
.container .scrollToTopButton:hover {
  opacity: 1;
  background: #3a8ece;
  border-color: #5da4d9;
}

.scrollToTopIcon {
  padding: 0 8px;
}

@media screen and (max-width: 768px) {
  .container .scrollToTopButton {
    border-color: #3eb747;
    background: #45bf4e;
  }
  .container .scrollToTopButton:hover {
    opacity: 1;
    border-color: #3eb747;
    background: #45bf4e;
  }
}